<template>
  <div>
    <custom-header></custom-header>
    <div class="container">
      <register-detail
      :is-edit="false"
      @showTip="handleTip(true)"
      @hideTip="handleTip(false)"
    ></register-detail>
    </div>
     <custom-footer></custom-footer>
    <!-- <custom-footer></custom-footer> -->
  </div>
</template>
<script>
import RegisterDetail from "./modules/RegisterDetail";

export default {
  name: "Register",
  components: { RegisterDetail
  },
  data() {
    return {
      needTip: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.needTip) {
      next();
    } else {
      this.$confirm("你还未提交数据，离开后数据将丢失", "您确定要离开吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        center: true,
        type: "warning",
      })
        .then((res) => {
          console.log(res);
          next();
        })
        .catch((err) => {
          console.log(err);
          next(false);
        });
    }
  },
  methods: {
    handleTip(val) {
      this.needTip = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.container{
  padding-top: 70px;
  width: 1200px;
  margin: 0 auto 30px;
}
</style>
