<template>
  <el-card class="form-container" shadow="never" v-loading="loading">
    <div class="fomm-content">
      <!-- 步骤栏 -->
      <el-steps :active="active" align-center class="my-step">
        <el-step title="手机号验证"
          ><span slot="icon" class="step-img">1</span></el-step
        >
        <el-step title="设置密码"
          ><span slot="icon" class="step-img">2</span></el-step
        >
        <el-step title="完成"
          ><span slot="icon" class="step-img">3</span></el-step
        >
      </el-steps>
      <!-- 手机验证码 -->
      <valiate-phone
        v-show="active == 0"
        v-model="listQuery"
        :is-edit="isEdit"
        ref="category"
        @nextStep="nextStep"
      >
      </valiate-phone>
      <!-- 设置密码 -->
      <set-password
        v-show="active == 1"
        v-model="listQuery"
        :is-edit="isEdit"
        ref="relation"
        @prevStep="prevStep"
         @finishCommit="handleCommit"
      ></set-password>
      <!-- 设置密码 -->
      <register-success
        v-show="active == 2"
        v-model="listQuery"
        :is-edit="isEdit"
        ref="relation"
      >
      </register-success>
      <!-- 弹窗 -->
    </div>
  </el-card>
</template>
<script>
import ValiatePhone from "./ValiatePhone";
import SetPassword from "./SetPassword";
import RegisterSuccess from "./RegisterSuccess";

import { register } from '@/api/index'

// 默认的商品参数
const defaultListQuery = {
  mobile: null,
  pwd: null,
  verifyCode: null,
  passwdCheck:null,
  isDefault: null,
  nickName: null,
};
// 商品详情
export default {
  name: "RegisterDetail",
  components: {
    ValiatePhone,
    SetPassword,
    RegisterSuccess,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      active: 0,
      listQuery: Object.assign({}, defaultListQuery),
    };
  },
  created() {},
  methods: {
    prevStep() {
      // 上一步
      if (this.active > 0) {
        this.active--;
      }
    },
    nextStep() {
      // 下一步
      this.active++;
      // 友情提示处理，判断是否真的要离开
      if (this.active > 0) {
        this.$emit("showTip");
      }
    },


    finishCommit() {
      console.log("finishCommit")
      this.$confirm("是否要注册", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        center: true,
        type: "warning",
      }).then(() => {
        this.loading = true;
        console.log("this.listQuery",this.listQuery)
        register(this.listQuery)
          .then(() => {
            this.$emit("hideTip");
            this.loading = false;
            this.active = 2;
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
    handleCommit() {
      console.log("handleCommit")
      this.finishCommit();
    },

  },
};
</script>
<style lang="scss" scoped>
.form-container {
  width: 100%;
  padding: 100px 0 200px 0;
}
.fomm-content {
  width: 80%;
  margin: 0 auto;
}
</style>
