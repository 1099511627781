<template>
  <div>
    <div class="my-form">
      <el-form
        ref="value"
        :model="value"
        :rules="loginRules"
        class="login-form"
    auto-complete="new-password"
        label-width="80px"
      >
      <el-form-item prop="nickName" label="昵称">
          <el-input
            v-model="value.nickName"
            name="nickName"
            type="text"
        auto-complete="new-password"
            maxlength="50"
            placeholder="请输入昵称"
          />
        </el-form-item>
        <el-form-item prop="pwd" label="密码">
          <el-input
            v-model="value.pwd"
            name="pwd"
            type="text"
         auto-complete="new-password"
            placeholder="请输入密码"
          />
        </el-form-item>
        <el-form-item prop="passwdCheck" label="确认密码">
          <el-input
            :type="passwordType"
            v-model="value.passwdCheck"
            name="passwdCheck"
       auto-complete="new-password"
            placeholder="请输入确认密码"
            @keyup.enter.native="handleLogin"
          />
        </el-form-item>
        <div class="small-btn">
          <el-button
            type="primary"
            @click.native.prevent="handlePrev"
            >上一步
          </el-button>
          <el-button
            type="primary"
            @click.native.prevent="handleLogin"
            >下一步
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "SetPassword",
  components: {},
  props:{
    value: Object,
  },
  data() {
    const validatePass = (rule, value, callback) => {
      let reg = /^[^\s]{6,10}$/
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (!reg.test(value)) {
        callback(new Error('密码6-10位，不包含空格'))
      } else {
        callback()
      }
    }
    const validatePassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.value.pwd) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    return {  
      loginRules: {
        pwd: [
          {
            required: true,
            message: '密码不能为空',
            trigger: 'blur'
          },
          {
            validator: validatePass,
            trigger: 'blur'
          }
        ],
        passwdCheck: [
          {
            required: true,
            validator: validatePassCheck,
            trigger: 'blur'
          }
        ]
      },
      passwordType: "password",
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    window.addEventListener("hashchange", this.afterQRScan);

    this.PATH_TO_MP4 =
      "https://atlimg.oss-cn-hangzhou.aliyuncs.com/web/2574.mp4";
  },
  destroyed() {
    window.removeEventListener("hashchange", this.afterQRScan);
  },
  methods: {
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
    },
    handlePrev() {
      this.$emit("prevStep");
    },
    handleLogin() {
      this.$refs.value.validate((valid) => {
        if (valid) {
          console.log("注册")
          this.$emit("finishCommit")
        } else {
          return false;
        }
      });
    },
  },
  
};
</script>
<style  lang="scss" scoped>
.login-form {
  width: 70%;
  margin: 50px auto;
}
</style>

