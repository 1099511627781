<template>
  <div>
    <div class="my-form">
      <el-form
        ref="value"
        :model="value"
        :rules="loginRules"
        class="login-form"
        auto-complete="new-password"
        label-width="80px"
      >
        <el-form-item prop="mobile" label="手机号">
          <el-input
            v-model="value.mobile"
            name="mobile"
            type="text"
            maxlength="11"
            auto-complete="new-password"
            placeholder="请输入手机号"
          />
        </el-form-item>
        <el-form-item prop="verifyCode" label="验证码">
          <div class="row">
            <el-input
              v-model="value.verifyCode"
              name="verifyCode"
              auto-complete="new-password"
              placeholder="请输入验证码"
              maxlength="6"
              @keyup.enter.native="handleNext"
            />
            <el-button
              @click="sendCode"
              :disabled="smsFlag"
              :style="{
                fontSize: '16px',
                backgroundColor: sendColor,
                borderColor: sendColor,
                color: '#fff',
              }"
            >
              {{ sendTime }}
            </el-button>
          </div>
        </el-form-item>
        <el-form-item prop="isDefault" label="">
          <div class="show-choice" style="position: absolute; top: -40px">
            <img
              v-if="value.isDefault"
              src="@/assets/images/choice.png"
              alt=""
              @click="changeDefault"
            />
            <img
              v-else
              src="@/assets/images/Notselected.png"
              alt=""
              @click="changeDefault"
            />
            <p @click="changeDefault">
              默认同意
              <!-- <span  class="color-primary" @click="showAgreement(3,'用户协议')">《用户协议》</span>
              <span  class="color-primary" @click="showAgreement(1,'隐私协议')">《隐私协议》</span> -->
              <span  class="color-primary" @click="showAgreement(3)">《用户协议》</span>
              <span  class="color-primary" @click="showAgreement(1)">《隐私协议》</span>
            </p>
          </div>
        </el-form-item>
        <div class="small-btn">
          <el-button
            :loading="loading"
            type="primary"
            @click.native.prevent="handleNext('value')"
            >下一步
          </el-button>
        </div>
      </el-form>
    </div>
    <!-- 弹窗 -->
    <agree-modal ref="agreeModal"></agree-modal>
  </div>
</template>

<script>
import { sendSms } from "@/api/index";
import AgreeModal from '@/components/AgreeModal'
export default {
  name: "ValidatePhone",
  components: {
    AgreeModal
  },
  props: {
    value: Object,
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else if (!/^1[0-9]{10}$/.test(value)) {
        callback("请填写正确的手机号");
      } else {
        callback();
      }
    };
    return {
      sendTime: "获取验证码",
      sendColor: "#CAA774",
      snsMsgWait: 60,
      smsFlag: false,
      loginRules: {
        mobile: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        verifyCode: [
          { required: true, message: "验证码不允许为空", trigger: "blur" },
        ],
        isDefault: [{ required: true, message: "请勾选", trigger: "blur" }],
      },
      loading: false,
    };
  },
  methods: {
    // showAgreement(type,name){
    //   // this.$refs.agreeModal.show(type,name)
    //   this.$router.push('/agreement?type=' + type)
    // },
      showAgreement(type){
      // this.$refs.agreeModal.show(type,name)
      this.$router.push('/agreement?type=' + type)
    },
    changeDefault() {
      this.value.isDefault = this.value.isDefault ? 0 : 1;
    },
    // 获取验证码
    sendCode() {
      if (!/^1\d{10}$/.test(this.value.mobile)) {
        this.$message.info("请填写正确的手机号");
        return;
      }
      var params = {
        mobile: this.value.mobile,
        type: 1,
      };
      sendSms(params).then(() => {
        this.$message.success("短信发送成功");
        this.countDown();
      });
    },
    countDown() {
      // 60秒后重新获取验证码
      var inter = setInterval(
        function () {
          this.smsFlag = true;
          this.sendColor = "#E0E0E0";
          this.sendTime = this.snsMsgWait + "s后可重发";
          this.snsMsgWait = this.snsMsgWait - 1;
          if (this.snsMsgWait < 0) {
            clearInterval(inter);
            this.smsFlag = false;
            this.sendColor = "#CAA774";
            this.sendTime = "获取验证码";
            this.snsMsgWait = 60;
          }
        }.bind(this),
        1000
      );
    },
    handleNext(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("nextStep");
        }
        this.loading = false;
      });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.login-form {
  width: 70%;
  margin: 50px auto;
}

.show-choice {
  display: flex;
  align-items: center;
  margin-top: 10px;

  p {
    color: #c6ced4;
    font-size: 14px;
    cursor: pointer;
  }
  img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    cursor: pointer;
  }
}
</style>

