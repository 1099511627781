<template>
  <el-dialog :visible.sync="visible" :title="title" center width="50%">
    <div class="my-form">
      <div class="show-content" v-html="detail.description"></div>
      <div class="small-btn">
        <el-button type="primary" @click.native.prevent="handleFinishCommit"
          >已阅读
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getProtocol } from "@/api/index";

// 添加类型分类
export default {
  name: "agreeModal",
  data() {
    return {
      type: null,
      visible: false,
      title: null,
      loading: false,
      detail: {
        description: null,
      },
    };
  },
  created() {},
  methods: {
    show(data, name) {
      this.type = data;
      this.visible = true;
      this.loading = false;
      this.title = name;
      this.getDetail();
    },
    getDetail() {
      getProtocol({ type: this.type }).then((res) => {
        console.log(res);
        this.detail = res.data;
      });
    },
    submit() {
      // 提交保存
      this.$refs.refuseForm.validate((valid) => {
        if (valid) {
          // 验证通过
          this.loading = true;
          //   updateMemberPhone(this.model).then(res => {
          //     this.loading = false
          //     this.$emit('ok')
          //     this.close()
          //   }).catch(err => {
          //     this.loading = false
          //   })
        }
      });
    },
    close() {
      this.$emit("close");
      this.visible = false;
    },
  },
};
</script>
<style scoped>
.show-content {
  height: 25vw;
  overflow: auto;
  border: 2px solid #e0e0e0;
  padding: 30px;
}
.show-content::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.show-content::-webkit-scrollbar-track {
  background: #f4f4f4;
  border-radius: 0;
  border-left: 2px solid #e0e0e0;
}
.show-content::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
.show-content::-webkit-scrollbar-thumb:hover {
  background: #c8c8c8;
}
.show-content::-webkit-scrollbar-corner {
  background: #179a16;
}
.my-form .small-btn{
    margin-top: 20px;
}
</style>