<template>
  <div class="page">
    <img src="@/assets/images/success.png" alt="" class="success-img" />
    <div class="small-btn">
      <el-button type="primary" @click.native.prevent="handleLogin"
        >立即登录
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  components: {},
  data() {
    return {};
  },
  methods: {
    handleLogin(){
      this.$router.push({
        path: '/login'
      })
    }
  }
};
</script>
<style  lang="scss" scoped>
.page {
  text-align: center;
}
.success-img {
  width: 280px;
  margin-top: 60px;
}
</style>

