import { render, staticRenderFns } from "./ValiatePhone.vue?vue&type=template&id=6f8aff35&scoped=true&"
import script from "./ValiatePhone.vue?vue&type=script&lang=js&"
export * from "./ValiatePhone.vue?vue&type=script&lang=js&"
import style0 from "./ValiatePhone.vue?vue&type=style&index=0&id=6f8aff35&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f8aff35",
  null
  
)

export default component.exports